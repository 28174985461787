<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @upload-success="handleUploadSuccess"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      :width="1000"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form
        :form="form"
        :col="2"
        ref="lzForm"
        @change-select="handleChangeSelect">
      </lz-form>
    </lz-dialog>
  </div>
</template>
<script>
const status = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
];
const cardType = [
  { label: '普通卡券', value: 1 },
  { label: '二维码', value: 2 },
  { label: '链接', value: 3 },
];

export default {
  data() {
    return {
      attachmentId: '',
      importType: '',
      search: [
        { name: '产品名称', type: 'text', fieldName: 'goods', value: '' },
        { name: '供应商名称', type: 'text', fieldName: 'supplier', value: '' },
        { name: '兑换码', type: 'text', fieldName: 'code', value: '' },
        { name: '类型', type: 'select', fieldName: 'card_type', value: '', options: cardType },
        { name: '是否兑换', type: 'select', fieldName: 'is_exchange', value: '', options: status },
        // { name: '产品ID', type: 'text', fieldName: 'goods_id', value: '', width: '150px' },
        // { name: '订单ID', type: 'text', fieldName: 'order_id', value: '', width: '150px' },
        // {
        //   fieldName: 'supplier_id',
        //   type: 'select',
        //   name: '供应商',
        //   value: '',
        //   options: [],
        //   remote: true,
        //   props: {
        //     url: '/supplier/list',
        //     value: 'id',
        //     label: 'name',
        //     params: { is_order: 1 },
        //   },
        // },
        // {
        //   fieldName: 'price_title_id',
        //   type: 'select',
        //   name: '礼一价格',
        //   value: '',
        //   options: [],
        //   remote: true,
        //   props: {
        //     url: '/price-title/list',
        //     value: 'id',
        //     label: 'name',
        //   },
        // },
      ],
      config: {
        url: '/card/list',
        setTableFormat: (response) => {
          response.forEach((el) => {
            this.$set(el, 'card_pwd_fomate', el.show_pwd ? el.card_pwd : '***');
            this.$set(el, 'attachment_id', !el.attachment_id ? [] : [el.attachment]);
            if (el.code) {
              el.htmlButton.push({ name: '查看订单', key: 'order', type: 'success' });
            }
          });
          return response;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '类型', prop: 'card_type_name' },
        { name: '卡号', prop: 'card_num' },
        { name: '密码', prop: 'card_pwd_fomate' },
        { name: '产品名称', prop: 'title' },
        { name: '供应商', prop: 'supplier_name' },
        { name: '入库时间', prop: 'create_time' },
        {
          name: '是否兑换',
          func: (ops) => (ops.is_exchange ? '是' : '否'),
        },
        { name: '兑换码', prop: 'code' },
        { name: '兑换时间', prop: 'exchange_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [
        {
          fieldName: 'card_type',
          type: 'select',
          name: '类型',
          required: true,
          value: '',
          options: cardType,
        },
        { fieldName: 'card_num', type: 'text', name: '卡券名称', value: '', required: false },
        { fieldName: 'card_pwd', type: 'password', name: '卡券密码', value: '', required: false },
        {
          fieldName: 'goods_id',
          type: 'select',
          name: '产品名称',
          required: true,
          value: '',
          options: [],
          remoteMethod: (val) => {
            this.$baseHttp.get(`/goods/list?keyword=${val}&is_virtual=1`).then((response) => {
              const goods = this.form.find((r) => r.fieldName === 'goods_id');
              this.$set(goods, 'options', response.data.data.map((r) => ({
                ...r,
                label: r.title,
                value: r.id,
              })));
            });
          },
        },
        {
          fieldName: 'supplier_id',
          type: 'select',
          name: '供应商',
          required: true,
          value: '',
          options: [],
        },
        {
          fieldName: 'attachment_id',
          type: 'upload',
          name: '二维码图片',
          value: [],
          multiple: false,
          required: true,
          width: '100%',
          hide: true,
        },
        {
          fieldName: 'attachment_ids',
          type: 'upload',
          name: '二维码图片',
          value: [],
          multiple: true,
          required: true,
          width: '100%',
          hide: true,
        },
        {
          fieldName: 'status',
          type: 'select',
          name: '状态',
          required: true,
          value: '',
          options: [
            { label: '启用', value: 0 },
            { label: '禁用', value: 1 },
          ],
        },
        { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
    };
  },
  methods: {
    handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$basePrompt({
            content: '是否确定删除',
            callConfirm: async (memo) => {
              const response = await this.$baseHttp.delete(`/card/delete?ids=${element.id}&memo=${memo}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        case 'order':
          this.$router.push({
            name: 'order-list',
            query: {
              code: element.code,
            },
          });
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          let response;
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          // 如果第一个隐藏并且附件有值代表是带入
          if (this.importType === 'import' && this.attachmentId) {
            params.attachment_id = this.attachmentId;
            response = await this.$baseHttp.post('/card/import', params);
          } else if (this.importType === 'qrcode') {
            response = await this.$baseHttp.post('/card/import-qrcode', params);
          } else if (this.importType === 'add') {
            response = await this.$baseHttp.post('/card/save', params);
          }
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          this.importType = 'add';
          break;
        case 'import-qrcode':
          this.importType = 'qrcode';
          this.title = '选择产品和供应商';
          this.form.forEach((element) => {
            this.$set(element, 'hide', !['goods_id', 'supplier_id', 'attachment_ids'].includes(element.fieldName));
          });
          this.$refs.lzDialog.dialogVisible = true;
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      this.form.forEach((element) => {
        if (element.fieldName === 'attachment_id') {
          this.$set(element, 'hide', !response || response.card_type !== 2);
          return;
        }
        if (element.fieldName === 'card_type') {
          this.$set(element, 'options', cardType);
        }
        this.$set(element, 'hide', ['id', 'import_type', 'attachment_ids'].includes(element.fieldName));
        if (element.fieldName === 'card_pwd') {
          this.$set(element, 'hide', response && String(response.show_pwd) === '0');
        }
      });
      this.form.filter((r) => ['goods_id', 'supplier_id'].includes(r.fieldName)).forEach((el) => {
        if (el.fieldName === 'goods_id') {
          this.$set(el, 'options', response ? [
            { label: response.title, value: response.goods_id },
          ] : []);
        }
        if (el.fieldName === 'supplier_id') {
          this.$set(el, 'options', response ? [
            { label: response.supplier_name, value: response.supplier_id },
          ] : []);
        }
      });
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {},
        });
      });
    },
    handleChangeSelect(element) {
      if (element.fieldName === 'card_type') {
        const attachment = this.form.find((r) => r.fieldName === 'attachment_id');
        this.$set(attachment, 'hide', element.value !== '2');
        return;
      }
      if (element.fieldName !== 'goods_id') return;
      const ops = element.options.find((r) => String(r.id) === element.value);
      const supplier = this.form.find((r) => r.fieldName === 'supplier_id');
      this.$set(supplier, 'value', '');
      if (ops) {
        this.$set(supplier, 'options', ops.supplier.map((r) => ({
          ...r,
          label: r.supplier_name,
          value: r.supplier_id,
        })));
      }
    },
    handleUploadSuccess(response) {
      this.title = '选择产品和供应商';
      this.attachmentId = response.data[0].id;
      this.importType = 'import';
      this.form.forEach((element) => {
        this.$set(element, 'hide', !['goods_id', 'supplier_id', 'card_type'].includes(element.fieldName));
        if (element.fieldName === 'card_type') {
          this.$set(element, 'options', [
            { label: '普通卡券', value: 1 },
            { label: '链接', value: 3 },
          ]);
        }
      });
      this.$refs.lzDialog.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped></style>
